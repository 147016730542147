<template>

  <form>
    <div class="bg">&nbsp;</div>
    <div class="l" id="loginDiv">
      <div class="tInfo">
        <img src="@/assets/public/logo.jpg" alt="闲芯">
        <div>闲芯中台管理 v1.0</div>
      </div>
      <div class="components-input-demo-presuffix">
        <a-input placeholder="用户名" v-model:value="username" @pressEnter="loginEvent">
          <template #prefix>
            <user-outlined type="user"/>
          </template>
          <template #suffix>
            <a-tooltip title="请输入您注册的用户名">
              <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)"/>
            </a-tooltip>
          </template>
        </a-input>
      </div>
      <br/>
      <div class="components-input-demo-presuffix">
        <a-input-password placeholder="密码" autocomplete="false" v-model:value="password" @pressEnter="loginEvent">
          <template #prefix>
            <key-outlined/>
          </template>
        </a-input-password>
      </div>
      <br/>
      <a-spin class="loginButton" v-if="dataValue.loginEvent" tip="登录中..."></a-spin>
      <a-button class="loginButton" v-if="!dataValue.loginEvent"  type="primary" @click="loginEvent">登录</a-button>

      <div class="copy">©Copyright 2019-2022 芯链芯</div>
    </div>
  </form>
</template>

<script>
import {reactive, ref} from 'vue';
import store from "@/store/index";
import router from "@/router";
import {message} from "ant-design-vue";
import proxy from '@/utils/axios'

export default {
  name: "Login",
  setup() {

    let dataValue = reactive({
      loginEvent : false
    });

    // 清空登录信息
    store.commit("setJwt", "");

    let username = ref('');
    let password = ref('');


    function loginEvent() {

      if (username.value === "") {
        message.error("请输入用户名");
        return
      }

      if (password.value === "") {
        message.error("请输入密码");
        return
      }

      proxy.postEIC('/login', {
            'LogonAlias': username.value,
            'PassWord': password.value,
            'RepetitionLogin':1,
          })
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              store.commit("setJwt", res.data.jwt);
              dataValue.loginEvent = true;
              window.setTimeout( ()=>{
                window.open( "/", "_self" );
              }, 1000)


            }
          })
    }

    return {
      dataValue,
      loginEvent,
      username,
      password
    }
  }
}

</script>

<style scoped>

.l {
  position: fixed;
  width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  border-radius: 5px;
  opacity: 0.9;
  padding: 50px;
  border-top: 2px solid dodgerblue;
}

.tInfo img{
  width: 60px;
  float: left;
  border-radius: 50%;
}
.tInfo div{
  float: left;
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: bold;
  height: 68px;
  line-height: 60px;
  padding-left: 10px;
}

.bg {
  z-index: 1;
  width: 100%;
  height: 1000px;
  filter: blur(8px);
  overflow: hidden;
  background-image: url('../../assets/public/bg.webp')
}

.copy {
  clear: both;
  text-align: center;
  padding-top: 30px;
  font-size: 10px;
  color: #999999;
}

.loginButton {
  float: right;
  margin-left: 20px;
}
</style>

